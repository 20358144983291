"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@coreui/react");
const date_fns_1 = require("date-fns");
const react_2 = require("react");
const react_final_form_1 = require("react-final-form");
const components_1 = require("components");
const internal_portal_components_1 = require("internal-portal-components");
const FieldsUploads = ({ handleAction, contextCallback, }) => {
    // Hooks
    const formState = (0, react_final_form_1.useFormState)();
    const formContext = (0, react_final_form_1.useForm)();
    // Vars
    const editFilename = formState.values['editFilename'];
    // State
    const [isEdit, setIsEdit] = (0, react_2.useState)(editFilename);
    const [editOption, setEditOption] = (0, react_2.useState)(false);
    const [isUploading, setIsUploading] = (0, react_2.useState)(false);
    const [inputEvent, setInputEvent] = (0, react_2.useState)(null);
    const handleSubmit = (0, react_2.useCallback)(async () => {
        setIsUploading(true);
        await handleAction(inputEvent, 'upload');
        setIsUploading(false);
    }, [inputEvent]);
    const handleChange = async (e) => {
        setInputEvent(e);
        setEditOption(true);
    };
    // Effects
    (0, react_2.useEffect)(() => {
        setIsEdit(editFilename);
    }, [editFilename]);
    (0, react_2.useEffect)(() => {
        if (formContext && contextCallback) {
            contextCallback(formContext);
        }
    }, [formContext]);
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsxs)("div", { className: "", children: [(0, jsx_runtime_1.jsx)(internal_portal_components_1.FormSubTitle, { title: "Uploads", checkboxName: editOption ? 'editFilename' : undefined, checkboxText: editOption ? 'Edit Filename' : undefined }), isEdit && ((0, jsx_runtime_1.jsx)(react_1.CRow, { xs: { gutterX: 5 }, children: (0, jsx_runtime_1.jsx)(react_1.CCol, { md: 12, children: (0, jsx_runtime_1.jsx)(react_final_form_1.Field, { altclass: "coreUI", label: 'Edit Filename', name: "fileName", component: components_1.InputText, placeholder: "" }) }) })), isUploading ? ((0, jsx_runtime_1.jsx)(react_1.CProgress, { color: "primary", variant: "striped", animated: true, value: 25 })) : ((0, jsx_runtime_1.jsx)(react_1.CRow, { xs: { gutterX: 5 }, children: (0, jsx_runtime_1.jsx)(react_1.CCol, { md: 12, children: (0, jsx_runtime_1.jsxs)(react_1.CInputGroup, { className: "mb-3", children: [(0, jsx_runtime_1.jsx)(react_1.CFormInput, { type: "file", onChange: handleChange }), (0, jsx_runtime_1.jsx)(react_1.CButton, { onClick: handleSubmit, type: "button", color: "primary", disabled: inputEvent === null, children: "Upload" })] }) }) })), (0, jsx_runtime_1.jsx)(react_1.CRow, { children: (0, jsx_runtime_1.jsxs)(react_1.CCol, { md: 2, sm: 12, children: [(0, jsx_runtime_1.jsx)(react_final_form_1.Field, { altclass: "coreUI", placeholder: "mm/dd/yyyy", name: "expirationDate", label: 'Expiration Date', component: components_1.InputDateMask, type: "tel", mask: Date, autofix: "pad", min: new Date(DATE_NOW.year, DATE_NOW.month, DATE_NOW.day), max: new Date(DATE_MAX.year, DATE_MAX.month, DATE_MAX.day), lazy: true, unmask: false }), (0, jsx_runtime_1.jsx)(react_final_form_1.Field, { name: "id", component: 'input', type: "hidden" })] }) })] }) }));
};
// Date Constants
const TODAY = new Date();
const FROM_TODAY_MAX = (0, date_fns_1.addDays)(new Date(), 9000); // 24 years
const DATE_NOW = {
    month: TODAY.getMonth(),
    day: TODAY.getDay(),
    year: TODAY.getFullYear(),
};
const DATE_MAX = {
    month: FROM_TODAY_MAX.getMonth(),
    day: FROM_TODAY_MAX.getDay(),
    year: FROM_TODAY_MAX.getFullYear(),
};
exports.default = FieldsUploads;
