"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@coreui/react");
const react_2 = require("react");
const ToastBasic = ({ display, }) => {
    const [showModal, setShowModal] = (0, react_2.useState)(display.title || display.message ? true : false);
    const handleClose = () => {
        setShowModal(false);
    };
    (0, react_2.useEffect)(() => {
        if (display.title || display.message) {
            setShowModal(true);
        }
        else {
            setShowModal(false);
        }
    }, [display]);
    return ((0, jsx_runtime_1.jsx)("div", { className: "toast-container", children: (0, jsx_runtime_1.jsx)(react_1.CToaster, { className: "p-3", placement: "top-end", children: (0, jsx_runtime_1.jsxs)(react_1.CToast, { animation: true, autohide: true, visible: showModal, children: [(0, jsx_runtime_1.jsx)(react_1.CToastHeader, { children: (0, jsx_runtime_1.jsxs)("div", { className: "w100 flex space-between", children: [(0, jsx_runtime_1.jsxs)("div", { className: "flex align-center", children: [(0, jsx_runtime_1.jsx)("svg", { className: "rounded me-2", width: "20", height: "20", xmlns: "http://www.w3.org/2000/svg", preserveAspectRatio: "xMidYMid slice", focusable: "false", role: "img", children: (0, jsx_runtime_1.jsx)("rect", { width: "100%", height: "100%", fill: "#00FF00", radius: 20 }) }), "\u00A0\u00A0", (0, jsx_runtime_1.jsx)("div", { className: "fw-bold me-auto", children: display.title })] }), (0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(react_1.CToastClose, { onClick: handleClose, dark: true }) })] }) }), (0, jsx_runtime_1.jsx)(react_1.CToastBody, { children: display.message })] }) }) }));
};
exports.default = ToastBasic;
