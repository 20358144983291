"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@coreui/react");
const react_final_form_1 = require("react-final-form");
const components_1 = require("components");
const utils_1 = require("utils");
const FieldsBusinessDetails = () => {
    const formState = (0, react_final_form_1.useFormState)();
    const hasMultiOwners = formState.values['multipleOwners'];
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsxs)("div", { className: "", children: [(0, jsx_runtime_1.jsxs)(react_1.CRow, { xs: { gutterX: 5 }, children: [(0, jsx_runtime_1.jsx)(react_1.CCol, { lg: 4, md: 12, children: (0, jsx_runtime_1.jsx)(react_final_form_1.Field, { altclass: "coreUI", name: "ownerFirstName", label: "Owner's Name", component: components_1.InputText, placeholder: "", type: "text" }) }), (0, jsx_runtime_1.jsx)(react_1.CCol, { lg: 4, md: 12, children: (0, jsx_runtime_1.jsx)(react_final_form_1.Field, { altclass: "coreUI", name: "ownerLastName", label: "Owner's Name", component: components_1.InputText, placeholder: "", type: "text" }) }), (0, jsx_runtime_1.jsx)(react_1.CCol, { lg: 4, md: 12, children: (0, jsx_runtime_1.jsx)(react_final_form_1.Field, { altclass: "coreUI", name: "ownerSuffix", label: "Owner's Suffix", component: components_1.InputText, placeholder: "", type: "text" }) }), (0, jsx_runtime_1.jsx)(react_1.CCol, { lg: 4, md: 12 })] }), (0, jsx_runtime_1.jsxs)(react_1.CRow, { xs: { gutterX: 5, gutterY: 3 }, children: [(0, jsx_runtime_1.jsx)(react_1.CCol, { lg: 2, md: 12, children: (0, jsx_runtime_1.jsx)(react_final_form_1.Field, { altclass: "coreUI", name: "taxId", label: `Federal Tax ID`, component: components_1.InputPattern, allowEmptyFormatting: false, formattype: '##-#######', isAllowed: (values) => {
                                    const { floatValue, value, formattedValue } = values;
                                    return (value.length <= 10 ||
                                        (value === '' &&
                                            formattedValue === '' &&
                                            floatValue === undefined));
                                }, placeholder: "Enter phone", validate: utils_1.validateRequiredField, required: true }) }), (0, jsx_runtime_1.jsx)(react_1.CCol, { lg: 3, md: 12, children: (0, jsx_runtime_1.jsx)(react_final_form_1.Field, { altclass: "coreUI", name: "numOfLocations", label: 'Number of Locations', component: components_1.InputNumber, placeholder: "", type: "text", isAllowed: (values) => {
                                    const { floatValue, value, formattedValue } = values;
                                    return (value.length < 9 ||
                                        (value === '' &&
                                            formattedValue === '' &&
                                            floatValue === undefined));
                                } }) }), (0, jsx_runtime_1.jsx)(react_1.CCol, { lg: 4, md: 12, children: (0, jsx_runtime_1.jsx)(components_1.InputSelectNative, { name: "multipleOwners", label: 'Additional Owners of 20% or more?', options: [
                                    { value: 'true', key: 'Yes' },
                                    { value: 'false', key: 'No' },
                                ], altclass: "coreUI" }) }), (0, jsx_runtime_1.jsx)(react_1.CCol, { md: 12, children: hasMultiOwners === 'true' && ((0, jsx_runtime_1.jsx)(react_final_form_1.Field, { altclass: "coreUI", label: "Additional Owners' Names", name: "multipleOwnerNames", component: components_1.InputText, placeholder: "", type: "text" })) })] })] }) }));
};
exports.default = FieldsBusinessDetails;
