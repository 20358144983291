"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function toSnakeCase(str) {
    return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}
function camelToSnake(obj) {
    if (Array.isArray(obj)) {
        return obj.map((item) => camelToSnake(item)); // Recursively apply to arrays
    }
    else if (obj !== null && typeof obj === 'object') {
        return Object.keys(obj).reduce((acc, key) => {
            const snakeKey = toSnakeCase(key);
            acc[snakeKey] = camelToSnake(obj[key]); // Recursively apply to nested objects
            return acc;
        }, {});
    }
    else {
        return obj;
    }
}
exports.default = camelToSnake;
