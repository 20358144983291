"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const lp_components_1 = require("lp-components");
const react_1 = require("react");
const react_final_form_1 = require("react-final-form");
const components_1 = require("components");
const config_1 = require("config");
const merchant_management_v1_api_1 = require("merchant-management-v1-api");
const utils_1 = require("utils");
const components_2 = require("../components");
function PaymentDetailsForm({ lenderName, creditLimit, handleSubmit, derogatoryInd, internalRiskReason, showServiceDate, maxServiceDate, lenderProductType, currentLocationId, minPurchaseAmount, }) {
    const { data, isFetching } = (0, merchant_management_v1_api_1.useGetMerchantManagementV1LocationsIdProductCatalogsQuery)({
        id: currentLocationId,
    }, { skip: lenderProductType !== 'LTO' });
    const products = data && data.length > 0 ? data[0].products : undefined;
    const riskFlag = derogatoryInd === 'Y' &&
        internalRiskReason &&
        internalRiskReason != '' &&
        internalRiskReason.startsWith('RISKY');
    // Check if amount is greater than credit limit
    const validateAmount = (0, react_1.useCallback)((value) => {
        const parseValue = value ? (0, utils_1.toAmount)(value) : '';
        if (lenderName !== 'ADS') {
            // Err
            if (Number(parseValue) > Number(creditLimit)) {
                return 'Amount greater than credit limit';
            }
        }
        // Check minimum amount for AFF
        if (lenderName === 'AFF') {
            const MIN_AMOUNT_AFF = minPurchaseAmount ?? 500;
            // Err
            if (Number(value) < MIN_AMOUNT_AFF) {
                return `The minimum transaction amount is $${MIN_AMOUNT_AFF}, please enter an amount greater than or equal to $${MIN_AMOUNT_AFF}`;
            }
        }
        // No err
        if (parseValue) {
            return;
        }
    }, [creditLimit]);
    const beforeSubmit = (values) => {
        handleSubmit({
            ...values,
            amount: (0, utils_1.toAmount)(values.amount),
        });
    };
    return ((0, jsx_runtime_1.jsx)(react_final_form_1.Form, { onSubmit: beforeSubmit, render: ({ handleSubmit, values, hasValidationErrors }) => ((0, jsx_runtime_1.jsx)("div", { className: "start-application__form  m-t-50 sales", children: (0, jsx_runtime_1.jsxs)("form", { onSubmit: handleSubmit, className: "payment-details-form", children: [(0, jsx_runtime_1.jsxs)("div", { className: "checkbox-container", children: [(0, jsx_runtime_1.jsx)(react_final_form_1.Field, { id: "validIdProvided", name: "validIdProvided", component: lp_components_1.Checkbox, label: false, "data-testid": "valid-id-provided" }), "Please confirm the customer\u2019s identity with a valid photo ID: driver\u2019s license, government issued state, federal, military ID or passport."] }), (0, jsx_runtime_1.jsxs)("div", { className: "m-t-40", children: [(0, jsx_runtime_1.jsx)(react_final_form_1.Field, { name: "identificationConfirmation.type", component: lp_components_1.Select, label: 'Select Customer’s Identity Verification Method *', placeholder: "Select Customer\u2019s Identity Verification Method", options: config_1.verification_methods, validate: utils_1.validateRequiredField, "data-testid": "identification-confirmation-type" }), values.identificationConfirmation &&
                                (values.identificationConfirmation?.type ===
                                    'drivers-license' ||
                                    values.identificationConfirmation?.type === 'state-id') && ((0, jsx_runtime_1.jsx)("div", { className: "refund-amount data-of-birth", children: (0, jsx_runtime_1.jsx)(react_final_form_1.Field, { label: 'State *', name: "state", component: lp_components_1.Select, placeholder: "State", options: config_1.STATES, required: true, validate: utils_1.validateRequiredField, "data-testid": "state-select" }) }))] }), (0, jsx_runtime_1.jsxs)("div", { children: [lenderProductType === 'LTO' && products ? ((0, jsx_runtime_1.jsx)(components_2.ProductInformation, { products: products, values: values })) : null, isFetching ? (0, jsx_runtime_1.jsx)(components_1.Spinner, {}) : null, (0, jsx_runtime_1.jsx)("div", { className: "m-t-40 refund-request--header", children: "Payment Information" }), (0, jsx_runtime_1.jsxs)("div", { className: "one-line-form m-t-30", children: [showServiceDate && ((0, jsx_runtime_1.jsxs)("div", { className: "service-date", children: [(0, jsx_runtime_1.jsx)(react_final_form_1.Field, { name: "serviceDate", component: lp_components_1.DateInput, minDate: new Date(), maxDate: 
                                                // Since date is UTC, we need to convert it to local time
                                                maxServiceDate
                                                    ? new Date(new Date(maxServiceDate).setUTCHours(new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()))
                                                    : undefined, validate: utils_1.validateRequiredField, placeholder: "Date that services will be / has been rendered", label: "Service Date *" }), (0, jsx_runtime_1.jsx)("span", { className: "additional-info", children: "The Service Date must reflect the date when services will be rendered, not the date the order is placed. Funding/settlement will not take place until the later of, the service date or the date the contract is signed." })] })), (0, jsx_runtime_1.jsx)(react_final_form_1.Field, { id: "amount", component: components_1.InputNumber, label: "Amount *", name: "amount", placeholder: "Total Amount of Sale", preicon: "$", allowLeadingZeros: false, thousandsGroupStyle: "thousand", thousandSeparator: ",", validate: (0, utils_1.composeValidators)(utils_1.validateRequiredField, validateAmount), decimalScale: 2, isAllowed: (values) => {
                                            const { value } = values;
                                            return value.length < 18;
                                        }, disabled: lenderProductType === 'LTO' }), (0, jsx_runtime_1.jsx)(react_final_form_1.Field, { name: "referenceId", component: lp_components_1.Input, placeholder: "Reference ID", label: "Reference ID (Optional)", "data-testid": "reference-id" })] })] }), (0, jsx_runtime_1.jsx)("div", { className: "start-application__form--actions  m-b-50", children: (0, jsx_runtime_1.jsx)("div", { className: "submit", children: (0, jsx_runtime_1.jsx)(components_1.Button, { type: "submit", disabled: !values.validIdProvided || !!riskFlag || hasValidationErrors, disabledToolTip: config_1.RISK_MESSAGE, className: `button-warn ${!values.validIdProvided || riskFlag || hasValidationErrors
                                    ? `warn-disabled`
                                    : ``}`, "data-testid": "submit-payment-details", children: "CONTINUE" }) }) })] }) })) }));
}
exports.default = PaymentDetailsForm;
