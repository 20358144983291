"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@coreui/react");
const react_final_form_1 = require("react-final-form");
const components_1 = require("components");
const utils_1 = require("utils");
const FieldsContactDetails = () => {
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsxs)("div", { className: "", children: [(0, jsx_runtime_1.jsxs)(react_1.CRow, { xs: { gutterX: 5 }, children: [(0, jsx_runtime_1.jsx)(react_1.CCol, { lg: 4, md: 12, children: (0, jsx_runtime_1.jsx)(react_final_form_1.Field, { altclass: "coreUI", name: "primaryContactFirstName", label: 'Primary First Name', component: components_1.InputText, placeholder: "", type: "text" }) }), (0, jsx_runtime_1.jsx)(react_1.CCol, { lg: 4, md: 12, children: (0, jsx_runtime_1.jsx)(react_final_form_1.Field, { altclass: "coreUI", name: "primaryContactLastName", label: 'Primary Last Name', component: components_1.InputText, placeholder: "", type: "text" }) }), (0, jsx_runtime_1.jsx)(react_1.CCol, { lg: 4, md: 12, children: (0, jsx_runtime_1.jsx)(react_final_form_1.Field, { altclass: "coreUI", name: "primaryContactTitle", label: 'Primary Title', component: components_1.InputText, placeholder: "", type: "text" }) })] }), (0, jsx_runtime_1.jsxs)(react_1.CRow, { xs: { gutterX: 5 }, children: [(0, jsx_runtime_1.jsx)(react_1.CCol, { lg: 4, md: 12, children: (0, jsx_runtime_1.jsx)(react_final_form_1.Field, { altclass: "coreUI", name: "primaryContactPhone", label: `Primary Phone`, component: components_1.InputPattern, allowEmptyFormatting: false, formattype: '(###)-###-####', isAllowed: (values) => {
                                    const { floatValue, value, formattedValue } = values;
                                    return (value.length <= 14 ||
                                        (value === '' &&
                                            formattedValue === '' &&
                                            floatValue === undefined));
                                }, placeholder: "Enter phone", validate: utils_1.validateRequiredField, required: true }) }), (0, jsx_runtime_1.jsx)(react_1.CCol, { lg: 4, md: 12, children: (0, jsx_runtime_1.jsx)(react_final_form_1.Field, { altclass: "coreUI", name: "primaryContactEmail", label: 'Primary Email', component: components_1.InputText, placeholder: "", type: "text" }) }), (0, jsx_runtime_1.jsx)(react_1.CCol, { lg: 4, md: 12 })] })] }) }));
};
exports.default = FieldsContactDetails;
