"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@coreui/react");
const FieldsButtons = ({ pristine, isLoading, isDisabled, form, handleAction, }) => {
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsxs)("div", { className: "flex space-between col-gap-10", children: [!pristine && ((0, jsx_runtime_1.jsx)(react_1.CButton, { type: "button", color: "dark", "aria-disabled": isDisabled, onClick: (evt) => {
                        handleAction(evt, 'cancel', form);
                    }, children: "Cancel Changes" })), !pristine && ((0, jsx_runtime_1.jsxs)(react_1.CButton, { type: "submit", color: "primary", variant: "outline", "aria-disabled": isDisabled, name: "btnSave", children: [isLoading && ((0, jsx_runtime_1.jsx)(react_1.CSpinner, { as: "span", size: "sm", variant: "grow", "aria-hidden": "true" })), "\u00A0 Save Changes"] })), pristine && ((0, jsx_runtime_1.jsx)(react_1.CButton, { onClick: (evt) => handleAction(evt, 'reject'), as: "button", type: "button", color: "danger", variant: "outline", disabled: !pristine || isDisabled, "aria-disabled": !pristine || isDisabled, children: "Reject Application" })), pristine && ((0, jsx_runtime_1.jsx)(react_1.CButton, { onClick: (evt) => handleAction(evt, 'approve'), as: "button", type: "button", color: "primary", "aria-disabled": !pristine || isDisabled, children: "Approve & Submit" }))] }) }));
};
exports.default = FieldsButtons;
